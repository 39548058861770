import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { Grid, IconButton, Button, Typography, Box } from '@mui/material'
//import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HelpIcon from '@mui/icons-material/Help'
import { useHistory, useLocation } from 'react-router-dom'
import AuthModal from '../AuthModal'
import AccountDropdown from './AccountDropdown'
import FAQDropdown from './FAQDropdown'
//import Icon_White from '../logos/pngs/Icon_White.png'
import {ReactComponent as Icon_White} from '../logos/Logos SVG/Icon_White.svg'

export default function Header() {
  const theme = useTheme();
  const location = useLocation();
  console.log(location.pathname)
  //const theme = useTheme()
  const history = useHistory()
  const user = useSelector((state) => state.auth.user)
  const [authModalOpen, setAuthModalOpen] = useState(false)
  const yellow = theme.palette.yellow.main

  return (
    <Grid
      container
      style={{
        width: '100%',
        textAlign: 'center',
        maxHeight: 300,
        backgroundColor: theme.palette.primary.main,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AuthModal onClose={() => setAuthModalOpen(false)} isOpen={authModalOpen} />
      <Grid onClick={() => history.push('/')} item xs={1} style={{ cursor: 'pointer' }}>
        <Icon_White style={{height: 75, padding: 25}}/>

      </Grid>
      <Grid onClick={() => history.push('/')} item xs={3} style={{ cursor: 'pointer', justifyContent: 'center' }} sx={{pl:6, pr: 6}}>
        <Typography style={{color:'white', fontWeight: 500, fontSize: 20, fontFamily:'Jost, sans-serif'}}>
          Brand Name
        </Typography>
        <Box style={{width: '100%', backgroundColor: location.pathname === '/' ? yellow : 'transparent', height: 5}}/>

      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography onClick={() => history.push('/stores')} style={{cursor: 'pointer', color:'white', fontWeight: 500, fontSize: 18}}>
              STORES
            </Typography>
            <Box style={{width: '100%', backgroundColor: location.pathname === '/stores' ? yellow : 'transparent', height: 5}}>
            </Box>
          </Grid>
          <Grid item>
            <Typography onClick={() => history.push('/orders')} style={{cursor: 'pointer', color:'white', fontWeight: 500, fontSize: 18}}>
              ORDERS
            </Typography>
            <Box style={{width: '100%', backgroundColor: location.pathname === '/orders' ? yellow : 'transparent', height: 5}}>
            </Box>
          </Grid>
          <Grid item>
            <Typography onClick={() => history.push('/products')} style={{cursor: 'pointer', color:'white', fontWeight: 500, fontSize: 18}}>
              PRODUCTS
            </Typography>
            <Box style={{width: '100%', backgroundColor: location.pathname === '/products' ? yellow : 'transparent', height: 5}}>
            </Box>
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container style={{ justifyContent: 'flex-end' }} sx={{pr:3}}>

          {(user === undefined || user === null) && (
            <Button
              style={{ color: 'white' }}
              aria-label="auth"
              onClick={() => setAuthModalOpen(true)}
            >
              Sign In
            </Button>
          )}
      
          {(user !== undefined && user !== null) && (
            <AccountDropdown/>
          )}
            <FAQDropdown/>
          
        </Grid>
      </Grid>
    </Grid>
  )
}
