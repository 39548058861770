import {Grid, Typography, Button} from "@mui/material/";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { getAuth, signOut } from 'firebase/auth'

function Account() {
  const auth = getAuth()
  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      })
  }
  return (
    <Grid
      container
      justify="center"
      style={{ width: "100%", height: "100%"}}
    >
      <AccountCircle style={{fontSize: 48}} />

      <Grid item xs={12}>
        <Grid
          container
          justify="center"
          style={{ width: "100%", height: "100%"}}
        >
          <Button> Current Subscription </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center" style={{ width: '100%', height: '100%' }}>
          <Button onClick={signOutUser} color="secondary" variant="contained">
            {' '}
            Sign Out{' '}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Account;
