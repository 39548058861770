import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    storeModal: {
      open: false,
      editing: false
    }
  },
  reducers: {
    setStoreModal: (state, action) => {
      state.currentProduct = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setStoreModal,
} = uiSlice.actions;

export default uiSlice.reducer;
