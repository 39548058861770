import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material/'
import Image from 'material-ui-image'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import InitialLanding from './InitialLanding'
import CreateAccount from './CreateAccount'
import SignIn from './SignIn'
import InitialSubscriptions from './InitialSubscriptions'
import firebase from '../../firebase'

const auth = getAuth()

/*
auth.onAuthStateChanged(user => {
    if (user !== null) {
      dispatch(setUser(user));
      dispatch(setInitialListers(user));
    } else {
      dispatch(noUser());
    }
  });


  firebase.auth()
     .signInWithEmailAndPassword(email, password)
     .then(function(results) {
       dispatch({
         type: LOGIN_USER_SUCCESS,
         payload: results
       });
     })
     .catch(function(error) {
       dispatch({
         type: LOGIN_USER_FAIL,
         payload: error.message
       });
     });

     firebase.auth()
     .createUserWithEmailAndPassword(email, password)
     .then(function(results) {
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: results,
        user: user
      });
     })
     .catch(function(error) {
      dispatch({
        type: CREATE_USER_FAIL,
        payload: error.message
      });
     });



 */

function LandingSwitch(props) {
  const {goToCheckout} = props
  const [screen, setScreen] = useState(0)
  function createUser() {
    createUserWithEmailAndPassword(auth, 'testingemail@gmail.com', 'password')
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // ...
        console.log('User: ', user)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        // ..
        console.log('ERROR CODE: ', errorCode)
        console.log('ERROR Message: ', errorMessage)
      })
  }

  function loginUser() {
    signInWithEmailAndPassword(auth, 'testingemail@gmail.com', 'password')
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // ...
        console.log("USER: ", user)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log("ERROR: ", errorCode)
        console.log("ERROR MESSAGE: ", errorMessage)
      })
  }
  return (
    <Grid container justify="center" style={{ width: '100%', height: '100%' }}>
      <Button onClick={loginUser}>TESTING</Button>
      {screen === 0 && (
        <InitialLanding
          createUser={createUser}
          goToAccountCreation={() => setScreen(1)}
          goToSignIn={() => setScreen(2)}
        />
      )}
      {screen === 1 && (
        <CreateAccount createUser={createUser} goToSubscriptions={() => setScreen(3)} goBack={() => setScreen(0)} />
      )}
      {screen === 2 && <SignIn createUser={createUser} goBack={() => setScreen(0)} />}
      {screen === 3 && <InitialSubscriptions goToCheckout={goToCheckout} goBack={() => setScreen(0)} />}
    </Grid>
  )
}

export default LandingSwitch
