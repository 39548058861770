import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  TextField,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonBase,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@mui/material/'
import { makeStyles } from '@mui/styles'
import EditIcon from '@material-ui/icons/Edit'
import Image from 'material-ui-image'

import { useSelector, useDispatch } from 'react-redux'

import { doc, addDoc, collection, setDoc, updateDoc, arrayUnion } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { db, storage } from '../firebase'

import Categories from '../components/product/Categories'
import StoreAvailability from './StoreAvailability'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}))

export default function ProductEditModal({ editProduct, productID, product }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [category, setCategory] = React.useState(product.category)
  const [storeIDs, setStoreIDs] = React.useState([])

  const handleChangeCategory = (event) => {
    setCategory(event.target.value)
  }
  const uid = useSelector((state) => state.main.user.uid)
  const stores = useSelector((state) => state.main.stores)
  // Create a storage reference from our storage service
  const brandRef = ref(storage, uid)

  const productImageRefs = [
    ref(brandRef, `products/${productID}/1.jpg`),
    ref(brandRef, `products/${productID}/2.jpg`),
    ref(brandRef, `products/${productID}/3.jpg`),
    ref(brandRef, `products/${productID}/4.jpg`),
  ]

  console.log("PRODUCT EDIT MODAL: ", product)
  const downloadURLS = [...product.productDownloadURLs]
  const [productDownloadURLs, setProductDownloadURLs] = useState(downloadURLS)

  const getNewURLs = (url, index) => {
    console.log('getNewURLs CALLED: ', url, index)
    const temp = [...productDownloadURLs]
    temp[index] = url
    setProductDownloadURLs(temp)
  }

  const inputFiles = [useRef(null), useRef(null), useRef(null), useRef(null)]

  const [filters, setFilters] = React.useState([])

  // On file select (from the pop up)
  const onFileChange = (event, index) => {
    // Upload To Firebase Storage
    uploadBytes(productImageRefs[index], event.target.files[0]).then((snapshot) => {
      getDownloadURL(productImageRefs[index])
        .then((url) => {
          getNewURLs(url, index)
        })
        .catch((error) => {
          console.log('ERROR GETTING DOWNLOAD LINK: ', error)
          // Handle any errors
        })
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [name, setName] = React.useState(product.name)
  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const [description, setDescription] = React.useState(product.description)
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const [inventory, setInventory] = React.useState(product.inventory)
  const handleInventoryChange = (e) => {
    setInventory(e.target.value)
  }

  const handleEditProduct = () => {
    const productRef = doc(db, 'brands', uid, 'products', productID)
    const productDoc = setDoc(productRef, { name, description, inventory, filters, category, productDownloadURLs })
    // editProduct({ name, description, inventory, filters, category, productDownloadURLs })
    // Add Product ID To Each Store ID Document Along With Array Union For Category
    console.log("EDIT PRODUCT SSTRIDS: ", storeIDs);
    storeIDs.forEach((id) => {
      const storeRef = doc(db, 'stores', id)
      console.log("EDIT PRODUCT: ", category);
      updateDoc(storeRef, {
        productIDs: arrayUnion(productID),
        categories: arrayUnion(category),
      })
    })
    handleClose()
  }

  const addStoreID = (storeID) => {
    console.log("ADD STORE ID: storeID: ", storeID)
    console.log("ADD STORE ID: current", storeIDs )
    setStoreIDs([...storeIDs, storeID])
  }

  const setStoreIDsTest = (obj) => {
    setStoreIDs(obj)
  }

  return (
    <div>
      <Button size="large" color="primary" onClick={handleClickOpen}>
        <EditIcon /> Edit
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editing Product</DialogTitle>
        <DialogContent>
          <DialogContentText>Product Images</DialogContentText>
          <Grid container style={{ padding: 10, marginTop: 10, justifyContent: 'space-around' }}>
            {productImageRefs.map((imageRef, index) => (
              <Grid item style={{ padding: 10, marginBottom: 15 }} xs={5}>
                <input
                  style={{ display: 'none' }}
                  ref={inputFiles[index]}
                  type="file"
                  onChange={(e) => onFileChange(e, index)}
                />
                <ButtonBase
                  focusRipple
                  onClick={() => inputFiles[index].current.click()}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                  style={{
                    width: '100%',
                  }}
                >
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${productDownloadURLs[index]})`,
                    }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
                      Image {index + 1}
                      <span className={classes.imageMarked} />
                    </Typography>
                  </span>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>

          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            onChange={handleChangeCategory}
          >
            <MenuItem value="family">Family</MenuItem>
            <MenuItem value="together">Together</MenuItem>
            <MenuItem value="personal">Personal</MenuItem>
            <MenuItem value="lifestyle">Lifestyle</MenuItem>
            <MenuItem value="trending">Trending</MenuItem>
            <MenuItem value="designer">Designer</MenuItem>
          </Select>

          <DialogContentText style={{ paddingTop: 15 }}>Product Filters</DialogContentText>
          <Categories setFilters={setFilters} />

          <TextField
            value={name}
            onChange={handleNameChange}
            autoFocus
            margin="dense"
            id="name"
            label="Product Name"
            fullWidth
          />
          <TextField
            value={description}
            onChange={handleDescriptionChange}
            autoFocus
            margin="dense"
            id="name"
            label="Product Description"
            fullWidth
          />
          <DialogContentText style={{ paddingTop: 15 }}>Select Stores With Product Availability</DialogContentText>
          <StoreAvailability setStoreIDs={setStoreIDsTest} stores={stores} addStoreID={addStoreID} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditProduct} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
