import { Grid, Typography, Button, Fab, TextField, ButtonGroup } from '@mui/material'
//import UserAgreement from '../UserAgreement.pdf';

function Terms() {
  return (
    <Grid
      container
      justify="center"
      style={{
        minHeight:'100vh'
      }}
    >
      <object data="/useragreement.pdf" type="application/pdf" width="100%" height="100%" />
    </Grid>
    
  )
}

export default Terms
