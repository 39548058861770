const outerwear = [
  {title: "Bombers",icon: "icon1",subcategories: []},
  {title: "Denim",icon: "icon2",subcategories: []},
  {title: "Heavy Coats",icon: "icon2",subcategories: []},
  {title: "Leather",icon: "icon2",subcategories: []},
  {title: "Parkas",icon: "icon2",subcategories: []},
  {title: "Light Jackets",icon: "icon2",subcategories: []},
  {title: "Sweatshirts & Hoodies",icon: "icon2",subcategories: []},
  {title: "Raincoats",icon: "icon2",subcategories: []},
  {title: "Vests",icon: "icon2",subcategories: []},
]
export default outerwear;
