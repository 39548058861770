import React, {useEffect} from 'react'
import { Grid, Typography, Button, Fab, IconButton, ButtonGroup } from '@mui/material/'
import Image from 'material-ui-image'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { doc, addDoc, collection, setDoc, deleteDoc } from 'firebase/firestore'
import StoresTable from '../components/storepage/StoresTable'
import ProductCreationModal from '../modals/ProductCreationModal'
import { db } from '../firebase'
import {removeProductFromBrand} from '../redux/mainSlice'
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreCreationModal from "../modals/StoreCreationModal";
import { getStores } from '../redux/interactions'

function Store() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const uid = useSelector((state) => state.auth.user)

  useEffect(() => {
    getStores()
  }, [uid])

  // const trips = useSelector((state) => state.main.trips)
  // let history = useHistory()

  const addStore = async obj => {
    console.log("Test");
    //const storeDoc = await addDoc(collection(db, "stores"), obj);
  };

  const deleteProduct = async (productID) => {
    console.log("TRYING TO DELETE: ", productID);
    dispatch(removeProductFromBrand({id: productID}))
  }
  return (
    <Grid container justify="center" sx={{p:2}}>
      <Grid container sx={{ p:2, pl:4, pb:0 }}>
        <Typography color="primary" sx={{ fontWeight: 500, fontSize: 24 }}>
          Stores
        </Typography>
      </Grid>
      <Grid container sx={{ p:2, pl:4, pr:4, justifyContent:'space-between'}} >
        <Grid item>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <StoreCreationModal addStore={addStore} brandID={'uid'} />
        </Grid>
      </Grid>

      <Grid container sx={{ p:2, pl:4, pr:4}}>
        <StoresTable />
      </Grid>

    </Grid>
  )
}

export default Store
