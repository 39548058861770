import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './mainSlice'
import uiReducer from './uiSlice'
import authReducer from './authSlice'
import brandReducer from './brandSlice'
import storesReducer from './storesSlice'
import productsReducer from './productsSlice'

export const store = configureStore({
  reducer: {
    main: mainReducer,
    ui: uiReducer,
    auth: authReducer,
    brand: brandReducer,
    stores: storesReducer,
    products: productsReducer,
  },
})
