import React, {useState} from 'react'
import { Grid, Typography, Button, Fab, IconButton } from '@mui/material/'
import Image from 'material-ui-image'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/styles'

import OrdersTable from '../components/orders/OrdersTable'
import OrderModal from '../modals/OrderModal'
import { useSelector, useDispatch } from 'react-redux'
import FilterListIcon from '@mui/icons-material/FilterList';


function Orders() {
  const theme = useTheme()
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.main.orders)
  const [currentOrder, setCurrentOrder] = useState({})
  const [orderModalOpen, setOrderModalOpen] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const handleOrderRowClick = (order) => {
    setCurrentOrder(order)
    setOrderModalOpen(true)
  }
  let history = useHistory()



  return (
    <Grid container justify="center" sx={{p:2}}>
      <Grid container sx={{ p:2, pl:4, pb:0 }}>
        <Typography color="primary" sx={{ fontWeight: 500, fontSize: 24 }}>
          Orders
        </Typography>
      </Grid>
      <Grid container sx={{ p:2, pl:4, pr:4, justifyContent:'space-between'}} >
        <Grid item>
          <IconButton onClick={ () => setFilterOpen(!filterOpen) } style={{borderRadius: 0}}>
            <FilterListIcon />
          </IconButton>
        </Grid>
      </Grid>
      <OrderModal open={orderModalOpen} order={currentOrder} setOpenStatus={setOrderModalOpen}/>
      <Grid container sx={{ p:2, pl:4, pr:4}}>
        <OrdersTable handleOrderRowClick={handleOrderRowClick} orders={orders}/>
      </Grid>
    </Grid>
  )
}

export default Orders
