import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSelector, useDispatch} from "react-redux";
import MapBox from "../components/home/MapBox";
import { createStore } from '../redux/interactions'

export default function FormDialog({addStore, brandID}) {
  const [open, setOpen] = React.useState(false);

  const logoURL = useSelector(state => state.main.brandData.logoURL);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [name, setName] = React.useState("");
  const handleNameChange = e => {
    setName(e.target.value);
  };

  const [address, setAddress] = React.useState("");
  const handleAddressChange = e => {
    setAddress(e.target.value);
  };

  const [range, setRange] = React.useState("");
  const handleRangeChange = e => {
    setRange(e.target.value);
  };

  const [hours, setHours] = React.useState("");
  const handleHoursChange = e => {
    setHours(e.target.value);
  };

  const [repName, setRepName] = React.useState("");
  const handleRepNameChange = e => {
    setRepName(e.target.value);
  };

  const [repEmail, setRepEmail] = React.useState("");
  const handleRepEmailChange = e => {
    setRepEmail(e.target.value);
  };

  const [repPhone, setRepPhone] = React.useState("");
  const handleRepPhoneChange = e => {
    setRepPhone(e.target.value);
  };

  const [destinationData, setDestinationData] = React.useState("");

  const handleAddStore = async () => {
    console.log("Handling Add Store");
    const newStore = await createStore({
      name,
      address,
      delivery_range: range,
      hours,
      rep_name: repName,
      rep_email: repEmail,
      rep_phone: repPhone
    })
    console.log('newStore: ', newStore)
    /*
    addStore({
      name,
      address,
      range,
      hours,
      brandID,
      repName,
      repEmail,
      repPhone,
      productIDs: [],
      logoURL
    });
     */
    handleClose()
  }

  return (
    <div>
      <ButtonGroup color="redorange" disableElevation variant="contained" aria-label="outlined primary button group" >
        <Button onClick={handleClickOpen}> New Store </Button>
        <Button>
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Store</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add A New Store Location to Your Brand
          </DialogContentText>
          <TextField
            onChange={handleNameChange}
            autoFocus
            margin="dense"
            id="store-name"
            label="Store Identifier"
            fullWidth
          />
        <div style={{height: 500, width: '100%'}}>
            <MapBox setRange={setRange} setAddress={setAddress} setDestinationData={setDestinationData} />
          </div>
          <Typography>
            Address: {address}
          </Typography>
          <TextField
            onChange={handleHoursChange}
            margin="dense"
            id="hours"
            label="Store Hours"
            fullWidth
          />
          <TextField
            onChange={handleRepNameChange}
            margin="dense"
            id="range"
            label="Rep Name"
            fullWidth
          />
          <TextField
            onChange={handleRepEmailChange}
            margin="dense"
            id="range"
            label="Rep Email"
            fullWidth
          />
          <TextField
            onChange={handleRepPhoneChange}
            margin="dense"
            id="range"
            label="Rep Phone"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" sx={{pr:5}}>
            Cancel
          </Button>
          <Button onClick={handleAddStore} color="primary" variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
