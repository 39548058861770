import mens from "./mens";
import womens from "./womens";

const fashion = [
  { title: "Men", icon: "icon1", subcategories: mens },
  { title: "Women", icon: "icon2", subcategories: womens },
  { title: "Children", icon: "icon2", subcategories: mens },
];

export default fashion;
