import topsWomen from "./topsWomen";
import bottomsWomens from "./bottomsWomens";
import outerwear from "./outerwear";
import footwear from "./footwear";
import formalWomen from "./formalWomen";
import swimWomen from "./swimWomen";
import sportWomen from "./sportWomen";
import dresses from "./dresses";
import underwearWomen from "./underwearWomen";

const womens = [
  { title: "Tops", icon: "icon1", subcategories: topsWomen },
  { title: "Bottoms", icon: "icon2", subcategories: bottomsWomens },
  { title: "Outerwear", icon: "icon2", subcategories: outerwear },
  { title: "Footwear", icon: "icon2", subcategories: footwear },
  { title: "Formal", icon: "icon2", subcategories: formalWomen },
  { title: "Swimwear", icon: "icon2", subcategories: swimWomen },
  { title: "Sportswear", icon: "icon2", subcategories: sportWomen },
  { title: "Underwear", icon: "icon2", subcategories: underwearWomen },
  { title: "Socks", icon: "icon2", subcategories: [] },
  { title: "Dresses", icon: "icon2", subcategories: dresses },
];

export default womens;
