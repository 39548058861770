import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Fab, TextField, ButtonGroup, Card, CircularProgress } from '@mui/material/'
import AddIcon from '@material-ui/icons/Add'
import Pagination from '@material-ui/lab/Pagination'
import { doc, updateDoc, arrayUnion, collection, query, where, getDocs } from 'firebase/firestore'
import { useSelector, useDispatch } from 'react-redux'
import Products from '../components/luggage/Products'
import Categories from '../components/product/Categories'
import { db } from '../firebase'

function Brand() {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const currentBrand = useSelector((state) => state.main.currentBrand)
  const brandID = currentBrand? currentBrand.id:'null'
  // const currentMember = useSelector((state) => state.main.currentBrand)
  const dispatch = useDispatch()
  const getProducts = async () => {
    setLoading(true)
    const productsRef = collection(db, 'brands', brandID, 'products')
    // Create a query against the collection.
    const productsQuery = query(productsRef)
    const querySnapshot = await getDocs(productsQuery)
    let newProducts = []
    querySnapshot.forEach((productDoc) => {
      // doc.data() is never undefined for query doc snapshots
      const dataObj = productDoc.data()
      dataObj.id = productDoc.id
      newProducts.push(dataObj)
      console.log('Product: ', productDoc.data())
    })
    setProducts(newProducts)
    setLoading(false)
  }
  useEffect(() => {
    getProducts()
  }, [currentBrand]);
  return (
    <Grid
      container
      justify="center"
      style={{
        height: '100%',
        marginTop: 24,
      }}
    >
      <Grid container justify="center" style={{ height: '100%' }}>
        <Categories />
      </Grid>
      <Grid container style={{ width: '100%' }}>
        {loading && <CircularProgress/>}
        {products.map((product, index) => (
          <Grid item xs={6} md={4} lg={3} style={{ padding: 10 }}>
            <Products product={product}/>
          </Grid>
        ))}
      </Grid>
      <Grid container style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <Grid item style={{ padding: 10 }}>
          <Pagination count={10} color="primary" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Brand
