import { Amplify, Storage } from 'aws-amplify'

/**
 * [uploadBrandImage description]
 * @param  {[type]} file               [description]
 * @return {[type]}              [description]
 */
async function uploadBrandImage(file, location) {
  console.log("Trying to upload Brand Image");
  try {
    const brandImage = await Storage.put(location, file, {
      contentType: 'image/*',
      resumable: true,
      completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`)
      },
      progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
      },
      errorCallback: (err) => {
        console.error('Unexpected error while uploading', err)
      }
    })
    console.log('brandImage: ', brandImage)
  } catch (error) {
    console.log('uploadBrandImage error: ', error)
  }
}

/**
 * [uploadProductImage description]
 * @param  {[type]} file               [description]
 * @return {[type]}              [description]
 */
async function uploadProductImage(file, location) {
  try {
    const productImage = await Storage.put(location, file, {
      contentType: 'image/*',
      resumable: true,
      completeCallback: (event) => {
        console.log('Successfully uploaded: ', event)
      },
      progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
      },
      errorCallback: (err) => {
        console.error('Unexpected error while uploading', err)
      }
    })
    console.log('productImage: ', productImage)
  } catch (error) {
    console.log('productImage error: ', error)
  }
}

async function getBrandImage(key) {
  try {
    console.log("GETTING KEY: ", key);
    const format = key.replace('tripwardrobe-storage192857-staging/', '')
    console.log("FORMATTED: ", format);
    const signedURL = await Storage.get(format)
    console.log('signedURL: ', signedURL)
    return signedURL
  } catch (error) {
    console.log('productImage error: ', error)
  }
}

/**
 * Copies brand image from temporary location to permanent product image location
 * @param {*} userId
 * @param {*} productId 
 * @param {*} index 
 */
async function copyBrandImage(userId, productId, index) {
  try {
    const res = await Storage.copy({key: `${userId}/temp/${index}.png`}, {key: `productimages/${userId}/${productId}/${index}.png`})
    console.log("Response: ", res);
  } catch (error) {
    console.log('error: ',error);
  }
}

export { uploadBrandImage, uploadProductImage, getBrandImage, copyBrandImage }
