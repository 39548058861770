import { Hub, Logger } from 'aws-amplify'
import { Auth } from 'aws-amplify'
import { store } from '../store';
// import {setUser} from '../authSlice.js'
import {
  setUser,
  setUserAttributes
} from '../authSlice'

const logger = new Logger('My-Logger');

const listener = (data) => {
    console.log("Data: ", data);
    switch (data.payload.event) {
        case 'signIn':
      console.log('LOGGER LISTENER: ', data.payload.data.username)
            logger.info('user signed in: ', data.payload.data.username);
            store.dispatch(setUser(data.payload.data.username));
            break;
        case 'signUp':
            // dispatch some user function here with data.payload.data.user.username
            console.log("LOGGER LISTENER: ", data.payload.data.username);
            store.dispatch(setUser(data.payload.data.username))
            store.dispatch(setUserAttributes(data.payload.data.attributes))
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            break;
        case 'signIn_failure':
          // dispatch user sign in failure with data.payload.data.message also look into .code
          console.log("LOGGER LISTENER: ", data);
            logger.error('user sign in failed');
            break;
        case 'signUp_failure':
          // dispatch user sign in failure with data.payload.data.message also look into .code
          console.log("LOGGER LISTENER: ", data);
            logger.error('user sign up failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
    }
}

function listen() {
  console.log("TRYING TO LISTEN");
  try {
    Hub.listen('auth', listener);
  } catch (error) {
    console.log('error listening: ', error);
  }
}



/**
 * [signUp description]
 * @return {[user: CognitoUser;  userConfirmed: boolean; userSub: string;]} [description]
 */
async function signUp(email, password) {
  try {
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email, // optional
        //phone_number, // optional - E.164 number convention
        // other custom attributes
      }
    })
} catch (error) {
    console.log('error signing up:', error);
  }
}

/**
 * [signIn description]
 * @return {[type]} [description]
 */
async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    console.log("User: ", user);
  } catch (error) {
    console.log('error signing in', error);
  }
}

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

async function forgotPassword(username) {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

async function forgotPasswordSubmit(username, code, new_password) {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password);
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export {signIn, signOut, signUp, listen, forgotPassword, forgotPasswordSubmit}
