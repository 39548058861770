import { Grid, Typography, Button } from '@mui/material/'
import Image from 'material-ui-image'
import LandingSwitch from '../components/landing/LandingSwitch'
import { useHistory } from 'react-router-dom'

function Auth() {
  let history = useHistory()

  return (
    <Grid container justify="center" style={{ width: '100%', height: '100%' }}>
      <Grid item xs={4}>
        <Image style={{ height: 'auto', width: 'auto' }} src="http://loremflickr.com/200/300" />
      </Grid>
      <LandingSwitch goToCheckout={() => history.push('/checkout')}/>
    </Grid>
  )
}

export default Auth
