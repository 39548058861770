const tops = [
  { title: "Long Sleeve", icon: "icon1", subcategories: [] },
  { title: "Polo", icon: "icon2", subcategories: [] },
  { title: "Button-Up", icon: "icon2", subcategories: [] },
  { title: "Short Sleeve", icon: "icon2", subcategories: [] },
  { title: "Sweaters", icon: "icon2", subcategories: [] },
  { title: "Sleeveless", icon: "icon2", subcategories: [] },
  { title: "Crop-Tops", icon: "icon2", subcategories: [] },
  { title: "Tanks", icon: "icon2", subcategories: [] },
];

export default tops;
