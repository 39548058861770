import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


/* STORES DATA FORMAT
stores: {
  byId: {
    1: {
      name: 'test store 1',
      address: 'test address 1',
      delivery_range: 6.6,
      order_count: 0,
    },
  },
  allIds: [1],
},
 */

/**
 * [storesSlice description]
 * @type {[type]}
 */
export const storesSlice = createSlice({
  name: 'stores',
  initialState: {
    stores: {
      byId: {},
      allIds: [],
    },
  },
  reducers: {
    clearStores: (state, action) => {
      state.stores = {
          byId: {},
          allIds: [],
      }
    },
    setStoresData: (state, action) => {
      state.stores = action.payload
    },
    addStore: (state, action) => {
      state.stores.allIds = [...state.stores.allIds, action.payload.id]
      state.stores.byId[action.payload.id] = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setStoresData, addStore, clearStores } = storesSlice.actions

export default storesSlice.reducer
