import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCWoZ2yIGULJsyKvZ168od03adZVhteCRE",
  authDomain: "colier-consult.firebaseapp.com",
  projectId: "colier-consult",
  storageBucket: "colier-consult.appspot.com",
  messagingSenderId: "379269792178",
  appId: "1:379269792178:web:0a5e6199ac0723aa79e4ab",
  measurementId: "G-DHHKL1NKSC"
};

const firebase = initializeApp(firebaseConfig);
export const storage = getStorage(firebase);
export const db = getFirestore()
export default firebase
