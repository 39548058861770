import React, { useState } from 'react'
import { Grid, Typography, Button, TextField } from '@mui/material/'
import Image from 'material-ui-image'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import firebase from '../../firebase'

const auth = getAuth()

function CreateAccount(props) {
  const { goToSubscriptions } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [creating, setCreating] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  function createUser() {
    setCreating(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // ...
        console.log('User: ', user)
        setCreating(false)
        goToSubscriptions()
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        // ..
        console.log('ERROR CODE: ', errorCode)
        console.log('ERROR Message: ', errorMessage)
        setCreating(false)
      })
  }

  return (
    <Grid container justify="center" style={{ width: '100%', height: '100%' }}>
      <Grid container style={{ width: '100%', height: '100%' }} justify="center">
        <Grid item>
          <TextField onChange={handleEmailChange} label="Email" />
        </Grid>
      </Grid>

      <Grid container justify="center" style={{ width: '100%', height: '100%' }}>
        <Grid item>
          <TextField onChange={handlePasswordChange} label="Password" />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item>
          <Button disabled={creating} variant="contained" onClick={createUser}>
            {' '}
            Create Account{' '}
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item>---OR----</Grid>
      </Grid>

      <Grid container justify="center">
        <Grid item>
          <Button onClick={goToSubscriptions}> Facebook </Button>
        </Grid>
        <Grid item>
          <Button> Apple </Button>
        </Grid>
        <Grid item>
          <Button> Google </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateAccount
