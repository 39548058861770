import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import { store } from '../store'
import { setBrandData } from '../brandSlice'
import { setStoresData, addStore, clearStores } from '../storesSlice'
import { setProductsData } from '../productsSlice'

// TODO: Look into this format for potential updates
/*

I figured it out how we can achieve it:
you can do like this:

#set ($updates = "")
#foreach( $item in $ctx.args.input.keySet())
    #if($item)
        #set ($updates = "$updates, $item = '$ctx.args.input.get($item)'")
    #end
#end
--------used cognito as authentication , so user id will come in indentity
#set($user_id = ($ctx.identity.username))
#set($updates = "$updates, updated_at = '$util.time.nowFormatted('yyyy-MM-dd HH:mm:ssZ')'")
{
"version": "2018-05-29",
"statements": [
    $util.toJson("update public.tableName SET $updates.substring(1)  WHERE user_id = '$user_id'"),
    $util.toJson("select * from public.tableName WHERE user_id = '$user_id'")
]
}
 */

/**
 * [createBrand description]
 * @param  {[type]} brandDetails               [description]
 * @return {[type]}              [description]
 * Example Call:
 * createBrand({
 *   description: 'desctest1',
 *   owner: 'ownerTest1',
 *   taskStatus: 'statustest1',
 *   title: 'titletest1',
 * })
 */
async function createBrand(brandDetails) {
  try {
    console.log("CREATE BRAND DETAILS: ", brandDetails);
    const newBrand = await API.graphql(graphqlOperation(mutations.createBrand, brandDetails))
    console.log('newBrand: ', newBrand)
    if (newBrand.errors) {
      console.log("ERROR");
      return newBrand.errors
    }
    return newBrand.data.createBrand
  } catch (error) {
    console.log('createBrand error: ', error)
  }
}

/**
 * [getBrand description]
 * @return {[type]} [description]
 */
async function getBrand() {
  try {
    const brand = await API.graphql(graphqlOperation(queries.getBrand))
    store.dispatch(setBrandData(brand.data.getBrand))
    console.log('brand: ', brand)
  } catch (error) {
    console.log('getBrand error: ', error)
  }
}

/**
 * [createStore description]
 * @param  {[type]} storeDetails               [description]
 * @return {[type]}              [description]
 * Example Call:
 * createStore(
 *	 name: String!,
 *	 address: String!,
 *	 delivery_range: Float!,
 *	 hours: String!,
 *	 rep_name: String!,
 *	 rep_email: String!,
 *	 rep_phone: String!
 * ): Store
 */
async function createStore(storeDetails) {
  try {
    console.log("CREATE BRAND DETAILS: ", storeDetails);
    const newStore = await API.graphql(graphqlOperation(mutations.createStore, storeDetails))
    console.log('newStore: ', newStore)
    if (newStore.errors) {
      console.log("ERROR");
      return newStore.errors
    }
    return newStore.data.createStore
  } catch (error) {
    console.log('createStore error: ', error)
    return error
  }
}

/**
 * [getStores description]
 * @return {[type]} [description]
 */
async function getStores() {
  try {
    console.log("TRYING TO GET STORES");
    const stores = await API.graphql(graphqlOperation(queries.getStoresForBrand))
    console.log('stores: ', stores)
    store.dispatch(clearStores())
    stores.data.getStoresForBrand.forEach((st) => {
      store.dispatch(addStore(st))
    })
    // store.dispatch(setStoresData(stores.data.getStoresForBrand))
  } catch (error) {
    console.log('getStores error: ', error)
  }
}

/**
 * [createProduct description]
 * @param  {[type]} productDetails               [description]
 * @return {[type]}              [description]
 * Example Call:'$ctx.args.brand_id', '$ctx.args.name', '$ctx.args.description', $ctx.args.filters, '$ctx.args.description', $ctx.args.urls
 * createProduct(
 *	  brand_id: ID!,
		  name: String!,
		description: String!,
		filters: [String],
		category: String!,
		urls: [String]
 * ): Store
 */
 async function createProduct(productDetails) {
  try {
    console.log("CREATE BRAND DETAILS: ", productDetails);
    const newStore = await API.graphql(graphqlOperation(mutations.createProduct, productDetails))
    console.log('newStore: ', newStore)
    if (newStore.errors) {
      console.log("ERROR");
      return newStore.errors
    }
    return newStore.data.createProduct
  } catch (error) {
    console.log('createProduct error: ', error)
    return error
  }
}

/**
 * [listBrandProducts description]
 * @return {[type]}              [description]
 * Example Call:'$ctx.args.brand_id', '$ctx.args.name', '$ctx.args.description', $ctx.args.filters, '$ctx.args.description', $ctx.args.urls
 * createProduct(
 *	  brand_id: ID!,
		  name: String!,
		description: String!,
		filters: [String],
		category: String!,
		urls: [String]
 * ): Store
 */
    async function listBrandProducts() {
      try {
        console.log("listBrandProducts: ");
        const productsRes = await API.graphql(graphqlOperation(queries.getProductsForBrand))
        console.log('productsRes: ', productsRes)
        if (productsRes.errors) {
          console.log("ERROR: ", productsRes.errors);
          return productsRes.errors
        }
        store.dispatch(setProductsData(productsRes.data.getProductsForBrand))
        return productsRes.data.getProductsForBrand
        
      } catch (error) {
        console.log('listBrandProducts error: ', error)
        return error
      }
    }

    /**
 * [listBrandProducts description]
 * @return {[type]}              [description]
 * Example Call:'$ctx.args.brand_id', '$ctx.args.name', '$ctx.args.description', $ctx.args.filters, '$ctx.args.description', $ctx.args.urls
 * createProduct(
 *	  brand_id: ID!,
		  name: String!,
		description: String!,
		filters: [String],
		category: String!,
		urls: [String]
 * ): Store
 */
    async function setProductUrls(id, urls) {
      try {
        console.log("setProductUrls: ");
        const productUpdateRes = await API.graphql(graphqlOperation(mutations.updateProductUrls, {id,urls}))
        console.log('productUpdateRes: ', productUpdateRes)
        if (productUpdateRes.errors) {
          console.log("ERROR: ", productUpdateRes.errors);
          return productUpdateRes.errors
        }
        //store.dispatch(setProductsData(productsRes.data.getProductsForBrand))
        return productUpdateRes.data.updateProductUrls
        
      } catch (error) {
        console.log('setProductUrls error: ', error)
        return error
      }
    }


export { createBrand, getBrand, createStore, getStores, createProduct,listBrandProducts, setProductUrls }
