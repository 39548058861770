import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    brandData: {},
  },
  reducers: {
    setBrandData: (state, action) => {
      state.brandData = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setBrandData
} = brandSlice.actions;

export default brandSlice.reducer;
