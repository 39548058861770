import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
  },
  reducers: {
    setProductsData: (state, action) => {
      state.products = action.payload
    },
    addProduct: (state, action) => {
      state.products.allIds.concat(action.payload.id)
      state.products.byId[action.payload.id] = action.payload.id
    },
    pushProduct: (state, action) => {
      state.products = [ ...state.products, action.payload.product ]
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProductsData, addProduct, pushProduct } = productsSlice.actions

export default productsSlice.reducer
