import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

export default function AdditionalFilters() {
  const colors = [
    'red',
    'blue',
    'green',
    'yellow',
    'black',
    'tan',
    'white',
    'brown',
    'beige',
    'darkgrey',
    'darkgreen',
    'violet',
    'ivory',
    'orange',
    'pink',
    'peru',
  ]

  const sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']

  const [open, setOpen] = React.useState([false, false, false, false, false]);
  const [selectedColor, setSelectedColor] = React.useState([false, false, false, false,false, false, false, false,false, false, false, false,false, false, false, false]);
  const [selectedSizes, setSelectedSize] = React.useState([false, false, false, false,false, false, false, false,false, false, false, false,false, false, false, false]);


  const handleClick = (index) => {
    const copy = [...open]
    copy[index] = !copy[index]
    setOpen(copy)
  }

  const handleColorClick = (index) => {
    const newColors = [...selectedColor]
    const curVal = newColors[index]
    newColors[index] = !curVal
    setSelectedColor(newColors)
  }

  const handleSizeClick = (index) => {
    const newSizes = [...selectedSizes]
    const curVal = newSizes[index]
    newSizes[index] = !curVal
    setSelectedSize(newSizes)
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" sx={{fontWeight: 'bold', fontSize: 16}}>
          Additional Filters
        </ListSubheader>
      }
    >
      <Divider />
        <ListItemButton onClick={()=>handleClick(3)}>
          <ListItemText primary="Price Category" />
          {open[3] ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled"/>}
        </ListItemButton>
        <Collapse in={open[3]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <Checkbox sx={{ mr: 4 }} />
              <ListItemText primary="Family" secondary="$3/day" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Checkbox sx={{ mr: 4 }} />
              <ListItemText primary="Together" secondary="$4/day" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Checkbox sx={{ mr: 4 }} />
              <ListItemText primary="Personal" secondary="$5/day" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Checkbox sx={{ mr: 4 }} />
              <ListItemText primary="Lifestyle" secondary="$6/day" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Checkbox sx={{ mr: 4 }} />
              <ListItemText primary="Trending" secondary="$7/day" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Checkbox sx={{ mr: 4 }} />
              <ListItemText primary="Designer" secondary="$8/day" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />
          
      <ListItemButton onClick={()=>handleClick(0)}>
        <ListItemText primary="Size" />
        {open[0] ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled"/>}
      </ListItemButton>
      <Collapse in={open[0]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          { sizes.map((size, index) =>
            <ListItemButton onClick={()=>handleSizeClick(index)} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Checkbox sx={{ mr: 4 }} />
              </ListItemIcon>
              <ListItemText primary={size} />
            </ListItemButton>
          )}
        </List>
      </Collapse>
      <Divider />
      <ListItemButton  onClick={()=>handleClick(1)}>
        <ListItemText primary="Color" />
        {open[1] ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled"/>}
      </ListItemButton>
      <Collapse in={open[1]} timeout="auto" unmountOnExit>
        <Grid container spacing={2} sx={{p:2}} style={{justifyConent:'center', alignItems:'center'}}>
          { colors.map((color, index) =>
            <Grid item >
              <Box onClick={()=>handleColorClick(index)} style={{width: 20, height: 20, backgroundColor: color, boxShadow: selectedColor[index] ? '0px 0px 2px 2px #6B92A5' : null}} sx={{border: 1.5}}/>
            </Grid>
          )}
        </Grid>
      </Collapse>
      <Divider />




    </List>
  );
}
