import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ProductEditModal from '../../modals/ProductEditModal'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: '100%',
    display: 'flex',
    flexDirection:'column',
    '&:hover': {
      boxShadow: '10px 5px 5px #1f2c33'
    }
  },
  media: {
    cursor:'pointer',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height: 200,
    backgroundColor:'lightGrey',
    opacity: .75,
    '&:hover': {
      opacity: 1,
    }
  },
});

export default function ProductCard({product, productID, deleteProduct}) {
  const classes = useStyles();
  const handleDeleteProduct = () => {
    deleteProduct('productID')
  }
  return (
    <Card key={productID} className={classes.root}>
      <Box className={classes.media}>
        <img src={product.urls.length === 4 ? product.urls[0]: 'https://tripwardrobe.com/logo.svg'} style={{height: 200}} alt="Product Image"/>
      </Box>
      <Box style={{ height: 50}}>
        <Grid container style={{justifyContent:'center', height:'100%', alignItems:'center'}}>
          <Typography> {product.name}</Typography>
        </Grid>
      </Box>
    </Card>
  );
}
