import React, {useEffect, useState} from 'react'
import { useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import firebase from './firebase'
import {signIn, signOut, signUp, listen} from './redux/interactions'

import { Auth } from 'aws-amplify';

const auth = getAuth()

export default function AuthModal({ isOpen, onClose }) {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [authState, setAuthState] = useState(1)
  const [authError, setAuthError] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  function createUser() {
    console.log('CREATING USER')
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // ...
        console.log('User: ', user)
        onClose()
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        // ..
        console.log('ERROR CODE: ', errorCode)
        console.log('ERROR Message: ', errorMessage)
        setAuthError(errorMessage)
      })
  }

  function loginUser() {
    console.log("google login");
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        // ...
        console.log('USER: ', user)
        onClose()
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('ERROR: ', errorCode)
        console.log('ERROR MESSAGE: ', errorMessage)
        if(errorMessage === 'Firebase: Error (auth/wrong-password).') {
          setAuthError("Incorrect Password, please try again")
        } else {
          setAuthError(errorMessage)
        }
      })
  }

  useEffect(() => {
    listen();
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" style={{borderRadius:0}} PaperProps={{style:{borderRadius:0}}}>
      {authState === 0 && (
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, mb: 2 }} style={{color:'white'}}>
          Create An Account
        </DialogTitle>
      )}
      {authState === 1 && (
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, mb: 2 }} style={{color:'white'}}>
          Sign In
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>
          To subscribe to this website, please enter your email address here. We will send updates occasionally.
        </DialogContentText>
        <TextField
          InputProps={{
            style: { borderRadius: 0 },
          }}
          onChange={handleEmailChange}
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
        />
        <TextField
          InputProps={{
            style: { borderRadius: 0 },
          }}
          onChange={handlePasswordChange}
          autoFocus
          margin="dense"
          id="password"
          label="Password"
          type="password"
          fullWidth
        />
        {authError !== '' && <Typography color="error"> {authError} </Typography>}
        {authState === 0 && (
          <DialogContentText sx={{mt:1}}>
            <Button style={{textTransform: 'none', borderRadius:0}} variant='outlined' onClick={() => setAuthState(1)} color="primary">
              Already Have an Account?
            </Button>
          </DialogContentText>
        )}
        {authState === 1 && (
          <DialogContentText sx={{mt:1}}>
            <Button style={{textTransform: 'none', borderRadius:0}} variant='outlined' onClick={() => setAuthState(0)} color="primary">
              Don&apos;t have an Account?
            </Button>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{ m: 2, mt: 0 }} onClick={handleClose} style={{ borderRadius: 0 }} color="primary">
          Cancel
        </Button>

        {authState === 1 && (
          <Button
            variant="contained"
            sx={{ m: 2, mt: 0 }}
            disableElevation
            style={{ borderRadius: 0 }}
            onClick={() => signIn(email, password)}
            color="primary"
          >
            Login
          </Button>
        )}
        {authState === 0 && (
          <Button
            variant="contained"
            sx={{ m: 2, mt: 0 }}
            disableElevation
            style={{ borderRadius: 0 }}
            onClick={() => createUser()}
            color="primary"
          >
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
