import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import AddIcon from '@material-ui/icons/Add'
import { doc, getDoc, onSnapshot, collection, query, where } from 'firebase/firestore'
import Categories from '../components/product/Categories'
import Image from 'material-ui-image'

import { db } from '../firebase'

export default function OrderModal({ open, order, setOpenStatus }) {
  console.log("ORDER MODAL ORDER: ", order);
  const handleClose = () => {
    setOpenStatus(false)
  }
  const accountID = order.accountID
  const tripID = order.tripID
  const storeID = order.storeID

  const [memberInfo, setMemberInfo] = useState(null)
  const [tripInfo, setTripInfo] = useState(null)

  const getDBDoc = async (ref) => {
    console.log("GET DB DOC CALLED");
    const docSnap = await getDoc(ref)
    if (docSnap.exists()) {
      const data = docSnap.data()
      console.log('Document data:', data)
      return data
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!')
      return null
    }
  }

  useEffect(async () => {
    // Update the document title using the browser API
    console.log('USE EFFECT ON ORDERS MODAL CALLED: ', order)
    // const orderRef = collection(db, "stores")
    if (accountID) {
      const memberRef = doc(db, 'users', accountID)
      const memberDoc = await getDBDoc(memberRef)
      setMemberInfo(memberDoc)
    }
    if (tripID) {
      const tripRef = doc(db, 'users', accountID, 'trips', tripID)
      const tripDoc = await getDBDoc(tripRef)
      setTripInfo(tripDoc)
    }
  }, [accountID, tripID, order])

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Order Details</DialogTitle>
      <DialogContent>
        <DialogContentText>Address: {tripInfo!==null ? tripInfo.address: 'awaiting'}</DialogContentText>
        <DialogContentText>Check In Date: {tripInfo!==null ? tripInfo.checkInDate.toDate().toString(): 'awaiting'}</DialogContentText>
          {order.items &&
            order.items.map((item, index) => {
              return (
                <Grid container style={{padding: 5, margin:10, borderRadius: 15, backgroundColor:'lightgrey',flex: 1, alignItems:'center', justifyContent:'space-between'}}>
                  <Grid item style={{textAlign: 'left', padding:10}} xs={5}>
                    <DialogContentText>{item.item.name}</DialogContentText>
                    <DialogContentText>size/ variations here</DialogContentText>
                  </Grid>
                  <Grid item style={{padding: 10, borderRadius:15}} xs={3}>
                    <Image imageStyle={{borderRadius:15}} style={{borderRadius:15}} src={item.item.productDownloadURLs[0]} />
                  </Grid>
                </Grid>
              )
            })}
          <Button style={{ padding: 15, marginTop: 10, marginBottom: 10}} fullWidth onClick={handleClose} variant="contained" color="primary">
            Message Customer
          </Button>
          <Button style={{backgroundColor: 'lightgreen', padding: 15, marginTop: 10, marginBottom: 10}} fullWidth onClick={handleClose} variant="contained" onClick={handleClose} color="primary">
            Mark Order Fulfilled
          </Button>
          <Button style={{backgroundColor: 'tomato', padding: 15, marginTop: 10, marginBottom: 10}} fullWidth onClick={handleClose} variant="contained" onClick={handleClose} color="primary">
            Cancel Order
          </Button>
    </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
