import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    group: false,
    groupID: null,
    signedIn: false,
    user: null,
    initialLoad: false,
    registered: false,
    saving: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setUserAttributes: (state, action) => {
      state.userAttributes = action.payload
    },
    initialInfoComplete: (state, action) => {
      firestore()
        .collection('Users')
        .doc(state.user.uid)
        .set(action.payload)
        .then(() => {
          console.log('Registered');
        });
    },
    signInUser: (state, action) => {
      auth()
        .signInWithEmailAndPassword(
          action.payload.email,
          action.payload.password,
        )
        .then(() => {
          console.log('User account signed in!');
        })
        .catch(error => {
          console.error(error);
        });
    },
    signIn: (state, action) => {
      state.user = action.payload;
      state.signedIn = true;
    },
    setRegistration: state => {
      state.registered = true;
    },
    rejoinGroup: state => {
      state.group = true;
    },
    setGroupID: (state, action) => {
      state.groupID = action.payload;
      state.group = true;
    },
    leaveGroup: state => {
      state.group = false;
    },
    clearGroupID: state => {
      state.groupID = null;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    signOut: state => {
      auth()
        .signOut()
        .then(() => console.log('User signed out!'));
      state.user = null;
      state.signedIn = false;
      state.registered = false;
    },
    setUserDocData: (state, action) => {
      state.docData = action.payload;
    },
    setUserImage: (state, action) => {
      state.docData.images[action.payload.index] = {url: action.payload.url};
    },
    setSaving: (state, action) => {
      state.saving = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setUserAttributes,
  setRegistration,
  setUserDocData,
  signOut,
  createUser,
  initialInfoComplete,
  signIn,
  incrementByAmount,
  setUserImage,
  setSaving,
  signInUser,
  registration,
  setGroupID,
  rejoinGroup,
  leaveGroup,
  clearGroupID,
} = authSlice.actions;

export default authSlice.reducer;
