import React, { useState, useRef, useEffect } from 'react'
import Slider from '@mui/material/Slider'
// Maps
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import * as turf from '@turf/turf'
import ReactMapGL, { Source, Layer, ScaleControl } from 'react-map-gl'

const geocoder = new MapboxGeocoder({
  accessToken: mapboxgl.accessToken,
  mapboxgl,
})

mapboxgl.accessToken = 'pk.eyJ1Ijoic3RoZXRpYyIsImEiOiJjazFqbWc3a2kwM2ZmM250MXI4YjhydWgzIn0.U3GqypZ0XY2QX1fy0sDKwA'

function MapBox({ setRange, setAddress, setDestinationData }) {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lng, setLng] = useState(-70.9)
  const [lat, setLat] = useState(42.35)
  const [zoom, setZoom] = useState(9)
  const [radius, setRadius] = useState(5)
  // const dispatch = useDispatch()

  useEffect(() => {
    console.log('MAPBOX USEEFFECT: ', map.current)
    if (map.current) return // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom,
    })

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      flyTo: {
          bearing: 0,
          speed: 3,
          curve: 1,
          zoom: 10,
          easing: function (t) {
          return t;
          }
      },
      mapboxgl,
    })
    geocoder.on('result', function (result) {
      // SET GEOCODING HERE
      setDestinationData('address', result.result.place_name)
      setAddress(result.result.place_name)
      const center = result.result.center
      console.log('GEOCODER Center: ', center)
      setLat(center[1])
      setLng(center[0])
      console.log('GEOCODER RESULT: ', result.result.place_name)
    })
    map.current.addControl(geocoder)
  }, [])

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on('zoomend', () => {
      console.log('Map Layers: ', map.current)
      if (map.current.getLayer('polygon')) {
        map.current.removeLayer('polygon')
        map.current.removeSource('polygon')
      }
      setZoom(map.current.getZoom().toFixed(2))
      map.current.addSource('polygon', createGeoJSONCircle([parseFloat(lng), parseFloat(lat)], radius))
      map.current.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'polygon',
        layout: {},
        paint: {
          'fill-color': 'blue',
          'fill-opacity': 0.3,
        },
      })
    })
    map.current.on('load', function () {
      console.log('ONLOAD CALLED')
    })
  }, [lat, lng, radius])

  let createGeoJSONCircle = function (center, radiusInKm, points) {
    console.log('Creating Geo JSON: ', center, radiusInKm)
    console.log('Creating Geo JSON CONTER: ', center[0], center[1])
    if (!points) points = 64

    let coords = {
      latitude: center[1],
      longitude: center[0],
    }

    let km = radiusInKm

    let ret = []
    let distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180))
    let distanceY = km / 110.574

    let theta
    let x
    let y
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI)
      x = distanceX * Math.cos(theta)
      y = distanceY * Math.sin(theta)

      ret.push([coords.longitude + x, coords.latitude + y])
    }
    ret.push(ret[0])
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [ret],
            },
          },
        ],
      },
    }
  }

  return (
    <>
      <div ref={mapContainer} className="map-container" />{' '}
      <Slider
        onChangeCommitted={(e, value) => {
          const geojsonSource = map.current.getSource('polygon')
          const longitude = parseFloat(lng)
          const latitude = parseFloat(lat)
          const centerCheck = [longitude, latitude]
          geojsonSource.setData(createGeoJSONCircle(centerCheck, value).data)
          setRadius(value)
          setRange(value)
          map.current.triggerRepaint()
        }}
        step={.1}
        min={.1}
        max={10}
        defaultValue={radius}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
      Store Delivery Range
    </>
  )
}

export default MapBox
