const bottomsWomens = [
  { title: "Casual", icon: "icon1", subcategories: [] },
  { title: "Cropped", icon: "icon2", subcategories: [] },
  { title: "Denim", icon: "icon2", subcategories: [] },
  { title: "Leggings", icon: "icon2", subcategories: [] },
  { title: "Overalls & Jumps", icon: "icon2", subcategories: [] },
  { title: "Shorts", icon: "icon2", subcategories: [] },
  { title: "Skirts", icon: "icon2", subcategories: [] },
  { title: "Sweats & Joggers", icon: "icon2", subcategories: [] },
];

export default bottomsWomens;
