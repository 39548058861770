import details from "./dressDetail1";

const dresses = [
  { title: "Cocktail", icon: "icon1", subcategories: details },
  { title: "Club", icon: "icon2", subcategories: details },
  { title: "Formal", icon: "icon2", subcategories: details },
  { title: "Sundress", icon: "icon2", subcategories: details },
  { title: "Bridesmaid", icon: "icon2", subcategories: details },
];

export default dresses;
