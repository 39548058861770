import React, { useState, useRef, useEffect } from 'react'
import {useSelector, useDispatch} from "react-redux";
import { Card, CardContent, Typography, Grid, Button, Fab, TextField } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import Icon_Color from '../../logos/Logos SVG/Icon_Color.svg'
import { uploadBrandImage, createBrand, getBrand, getBrandImage } from '../../redux/interactions'
import SubscribeModal from '../../modals/SubscribeModal'

export default function BrandCard() {
  const uid = useSelector((state) => state.auth.user)
  const brandData = useSelector((state) => state.brand.brandData)
  const inputFile = useRef(null);
  const avatarRef = useRef(null)
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [currentDownloadURL, setCurrentDownloadURL] = useState(null);
  const [brandName, setBrandName] = useState('Brand Name');
  const [description, setDescription] = useState('');
  const [editingName, setEditingName] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [ scaledImage, setScaledImage ] = useState(null);
  const [ scaledImageBlob, setScaledImageBlob ] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageRawFile, setImageRawFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop: files => {
      setScaledImage(null)
      const file = files[0]
      setImageRawFile(file)
      const imageurl = URL.createObjectURL(file)
      setImageFile(imageurl)
    },
    accept: 'image/jpeg,image/png'
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onSaveAvatar = async () => {
    if (avatarRef) {
      const canvas = avatarRef.current.getImage()
      canvas.toBlob(async function(blob) {
        setScaledImageBlob(blob)
        console.log("AVATAR BLOB: ", blob);
        var url = URL.createObjectURL(blob);
        console.log("AVATAR URL: ", url);
        setScaledImage(url);
        await uploadBrandImage(blob, `${uid}/brandImage.png`)
      })
    }
  }

  const handleChangeImage = (event) => {
    setScaledImage(null)
    const file = event.target.files[0]
    setImageRawFile(file)
    console.log("imageRawFile: ", file);
    const imageurl = URL.createObjectURL(file)
    console.log("imageurl: ", imageurl);
    setImageFile(imageurl)
  };

  const handleNameEditClick = () => {
    if (editingName) {
      handleNameEditClick
    }
  }

  const handleNameChange = (e) => {
    setBrandName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleCreateBrand = async () => {
    const result = await createBrand({
      logo_key: 'tripwardrobe-storage192857-staging/418d90ae-7e61-4647-8347-36d8eb492225/brandImage.png',
      description,
      name: brandName,
    })
    console.log('Result: ', result)
  }

  useEffect(() => {
    if(uid !== null && uid !== undefined) {
      getBrand()
    }
  }, [uid])

  useEffect(() => {
    const handleBrandImage = async (key) => {
      const returned = await getBrandImage(brandData.logo_key)
      console.log("RETURNED: ", returned)
      setScaledImage(returned)
    }
    if(brandData.description && brandData.logo_key && brandData.name) {
      setBrandName(brandData.name)
      setDescription(brandData.description)
      handleBrandImage(brandData.logo_key)
    }
  }, [brandData])

  return (
    <Card sx={{ m: 5, p:5, minWidth:350 }} style={{width:'50%', textAlign:'center', maxWidth:600}}>
      <Grid container style={{alignItems:'center', textAlign:'left'}} sx={{pl:4, pr:4}}>
        <Grid item style={{flexGrow:1}}>
          {editingName && (
            <TextField id="outlined-basic" label="Brand Name" fullWidth variant="outlined" onChange={handleNameChange}/>
          )}
          {!editingName && (
            <Typography sx={{fontSize:28, fontWeight: 500}}> {brandName}</Typography>
          )}
        </Grid>
        <Grid item>
          <Fab color="secondary" size="small" onClick={()=> setEditingName(!editingName)}>
            {editingName ? <CheckIcon/> : <EditIcon/>}
          </Fab>
        </Grid>
      </Grid>
      <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} sm={10} md={8} lg={6} style={{ textAlign: 'center' }} sx={{p:2, m:2, borderColor:'secondary.main', border: 3 }}>
          <Grid
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            container
            style={{ alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          >
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>drag & drop a logo file or click to browse</p>
              {(!imageFile && scaledImage === null) && (<img src={Icon_Color} style={{width: 250, height: 250, opacity:.5}}/>)}
              {scaledImage && (<img src={scaledImage} style={{width: 250, height: 250}}/>)}
            </div>
          </Grid>


          {imageFile && scaledImage === null && (
            <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Grid item>
                <AvatarEditor
                  ref={avatarRef}
                  image={imageFile}
                  width={250}
                  height={250}
                  border={50}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1.2}
                  rotate={0}
                />
              </Grid>
              <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Button variant="contained" onClick={onSaveAvatar}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

      </Grid>
      <Grid container style={{alignItems:'center', textAlign:'left'}} sx={{pl:4, pr:4}}>
        <Grid item xs={11} style={{flexGrow:1}}>
          {editingDescription && (
            <TextField id="outlined-basic" label="description" variant="outlined" fullWidth multiline onChange={handleDescriptionChange} defaultValue={description}/>
          )}
          {!editingDescription && (
            <Typography variant="body2" fullWidth color="text.secondary" component="span">
              {description === '' ? 'Enter a brief description for your brand here... ' : description}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Fab color="secondary" size="small" onClick={()=> setEditingDescription(!editingDescription)}>
            {editingDescription ? <CheckIcon/> : <EditIcon/>}
          </Fab>
        </Grid>
      </Grid>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        Billing Section
        </Typography>
        <SubscribeModal />
      </CardContent>
      <Button variant='contained' color='primary' onClick={handleCreateBrand}>
        Save Brand
      </Button>
    </Card>
  );
}
