import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    user: null,
    tripData: {},
    cart: [],
    trips: {},
    currentTrip: null,
    members: [],
    tripMembers: [],
    accountMembers: [],
    currentBrand: null,
    currentMember: null,
    currentProduct: null,
    tripID: null,
    stores: {},
    brandData: {
      name: "",
      description: ""
    },
    orders: {},
    products: {}
  },
  reducers: {
    removeProductFromBrand: (state, action) => {
      console.log("REMOVE PRODUCT FROM BRAND: ", action.payload.id);
      delete state.products[action.payload.id]
    },
    setBrandDoc: (state, action) => {
      state.brandData = action.payload;
    },
    setCurrentTrip: (state, action) => {
      state.currentTrip = action.payload;
    },
    addOrder: (state, action) => {
      state.orders[action.payload.id] = action.payload.document;
    },
    addTrip: (state, action) => {
      state.trips[action.payload.id] = action.payload.document;
    },
    addStore: (state, action) => {
      state.stores[action.payload.id] = action.payload.document;
    },
    addProduct: (state, action) => {
      state.products[action.payload.id] = action.payload.document;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTripInfo: (state, action) => {
      state.tripData[action.payload.key] = action.payload.value;
    },
    addToCart: (state, action) => {
      state.cart.push({
        item: action.payload,
        brandID: state.currentBrand.id,
        member: state.currentMember.memberName
      });
    },
    addItemToMemberCart: (state, action) => {
      state.cart.push(action.payload);
    },
    pushNewMember: (state, action) => {
      state.members.push(action.payload);
    },
    addMemberToTrip: (state, action) => {
      state.tripMembers.push(action.payload);
    },
    pushSavedMember: (state, action) => {
      state.accountMembers.push(action.payload);
    },
    clearSavedMembers: state => {
      state.accountMembers = [];
    },
    setCurrentMember: (state, action) => {
      state.currentMember = action.payload;
    },
    setCurrentBrand: (state, action) => {
      state.currentBrand = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    },
    setTripIDForCart: (state, action) => {
      state.tripID = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  removeProductFromBrand,
  setBrandDoc,
  addStore,
  addProduct,
  setTripIDForCart,
  setCurrentProduct,
  setCurrentMember,
  setCurrentBrand,
  clearSavedMembers,
  pushSavedMember,
  addMemberToTrip,
  setUser,
  setTripInfo,
  addToCart,
  addTrip,
  addOrder,
  setCurrentTrip,
  addItemToMemberCart,
  pushNewMember
} = mainSlice.actions;

export default mainSlice.reducer;
