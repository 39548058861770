import React, {useEffect, useState} from 'react'
import { Grid, Typography, Button, Fab, IconButton, ButtonGroup, Box } from '@mui/material/'
import Image from 'material-ui-image'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { doc, addDoc, collection, setDoc, deleteDoc } from 'firebase/firestore'
import ProductCard from '../components/product/ProductCard'
import Categories from '../components/product/Categories'
import AdditionalFilters from '../components/product/AdditionalFilters'

//import StoresTable from '../components/storepage/StoresTable'
import ProductCreationModal from '../modals/ProductCreationModal'
import { db } from '../firebase'
import {removeProductFromBrand} from '../redux/mainSlice'
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getProducts, getProductsForBrand } from '../graphql/queries'
import { listBrandProducts } from '../redux/interactions'

function Products() {
  const theme = useTheme()
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.auth.uid)
  const products = useSelector((state) => state.products.products)
  const [mainFilters, setMainFilters] = useState(null);
  //const products = useSelector((state) => state.main.products)
  //let newProductRef
  //let newProductID
  //if(uid!==null && uid!==undefined){
  //  newProductRef = doc(collection(db, 'brands', uid, 'products'))
  //  newProductID = newProductRef.id
  //  console.log("NEWPRODUCT ID: ", newProductID)
  //} else {
  //  console.log("Something Went Wrong")
  //}
 
  useEffect(() => {
    listBrandProducts()
  }, [uid])
   
  const [filterOpen, setFilterOpen] = useState(false)
  // const trips = useSelector((state) => state.main.trips)
  const addProduct = async (obj) => {
    //const productDoc = await setDoc(newProductRef, obj)
  }
  let history = useHistory()

  const deleteProduct = async (productID) => {
    console.log("TRYING TO DELETE: ", productID);
    dispatch(removeProductFromBrand({id: productID}))
    await deleteDoc(doc(db, 'brands', uid, 'products', productID))
    // Remove from Redux as well
  }

  const handleSetMainFilters = (newFilters) => {
    console.log("newFilters: ", newFilters);
    setMainFilters(newFilters)
    //await deleteDoc(doc(db, 'brands', uid, 'products', productID))
    // Remove from Redux as well
  }

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <Fab
        color='redorange'
        style={{position: 'fixed', top:'50%', left:225}}
        onClick={()=>setFilterOpen(false)}
      >
        <ChevronLeftIcon/>
      </Fab>
      <AdditionalFilters />
    </Box>
  );

  return (
    <Grid container justify="center" sx={{pt: 4, pl:4, pr:4}}>
      <Drawer
        anchor={'left'}
        open={filterOpen}
        onClose={()=>setFilterOpen(false)}
      >
        {list()}
      </Drawer>

      <Grid container>
        <Grid container>
          <Typography color="primary" sx={{ pl:2, fontWeight: 500, fontSize: 24 }}>
            Products
          </Typography>
        </Grid>
        <Grid container sx={{ p:0, pl:4, pr:4, justifyContent:'space-between'}} >
          <Grid item xs={2} >
            <Grid container style={{justifyContent: 'left', height:'100%'}}>
              <IconButton onClick={ () => setFilterOpen(!filterOpen) } style={{borderRadius: 0}}>
                <FilterListIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={8} style={{textAlign: 'center'}}>
            <Typography>
              Filter by:
            </Typography>
            <Categories setFilters={handleSetMainFilters}/>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={6} sm={4} md={3} lg={2} style={{ padding: 15 }}>
          <ProductCreationModal productID={'newProductID'} addProduct={addProduct} />
        </Grid>
        {products.map((item, index) => (
          <Grid key={item.id} item xs={6} sm={4} md={3} lg={2} style={{ padding: 15 }}>
            <ProductCard deleteProduct={deleteProduct} productID={item.id} product={item} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Products
