import topsMen from "./topsMen";
import bottomsMens from "./bottomsMens";
import outerwear from "./outerwear";
import footwear from "./footwear";
import formalMen from "./formalMen";
import swimMen from "./swimMen";
import sportMen from "./sportMen";
import underwearMen from "./underwearMen";

const mens = [
  { title: "Tops", icon: "icon1", subcategories: topsMen },
  { title: "Bottoms", icon: "icon2", subcategories: bottomsMens },
  { title: "Outerwear", icon: "icon2", subcategories: outerwear },
  { title: "Footwear", icon: "icon2", subcategories: footwear },
  { title: "Formal", icon: "icon2", subcategories: formalMen },
  { title: "Swimwear", icon: "icon2", subcategories: swimMen },
  { title: "Sportswear", icon: "icon2", subcategories: sportMen },
  { title: "Underwear", icon: "icon2", subcategories: underwearMen },
  { title: "Socks", icon: "icon2", subcategories: [] },
];

export default mens;
