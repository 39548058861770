import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import orange from '@mui/material/colors/orange'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const theme = createTheme({
  typography: {
    fontFamily: 'Jost, sans-serif',
    //fontFamily: ['Jost', 'sans-serif', 'Noto Serif Display', 'serif'].join(','),
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      main: '#2A6280'
    },
    secondary: {
      main: '#6B92A5',
    },
    redorange: {
      main: '#DB6028',
      contrastText: 'white'
    },
    tan: {
      main: '#D2A18D',
    },
    yellow: {
      main: '#D3A02A',
    },
    offwhite: {
      main: '#ECECEC',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  status: {
    primary: orange[500],
    danger: orange[500],
  },
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <React.StrictMode>
          <App />
      </React.StrictMode>
    </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
