import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import { Grid, Typography } from '@mui/material'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
// STRIPE
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// REDUX
import { useSelector, useDispatch } from 'react-redux'
// firebase
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot, collection, query, where } from 'firebase/firestore'
import {
  setUser,
  setBrandDoc,
  addTrip,
  addOrder,
  pushSavedMember,
  clearSavedMembers,
  addStore,
  addProduct,
} from './redux/mainSlice'
import MainBar from './components/MainBar'
import Header from './components/Header'
import { Auth, Brand, Details, Home, Messages, Orders, Settings, Store, Account, Products, Terms } from './screens'
import { Auth as AWSAuth } from 'aws-amplify';

import { db } from './firebase'
import AuthModal from './modals/AuthModal'

const stripePromise = loadStripe('pk_test_OlL5F9gfcwKRi60SAfn8U7hS00gi0cUpXR')

function App() {
  const dispatch = useDispatch()
  /*
  also user.attributes has sub, email verified, and email
  */
  const user = useSelector((state) => state.auth.user)
  const [initialLoad, setInitialLoad] = useState(true)
  const [authModalOpen, setAuthModalOpen] = useState(true)

  useEffect(()=> {
    AWSAuth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => dispatch(setUser(user.username)))
    .catch(err => console.log(err));
  }, [] )

  return (
    <Elements stripe={stripePromise}>
      {user === undefined ||
        (user === null && (
        <>
        <Switch>
          <Grid
            style={{
              display: 'flex',
              flexGrow: 1,
              width: '100%',
              height: '100%',
            }}
            xs={12}
          >
            <Route exact path="/terms">
              <Terms />
            </Route>
            <Route exact path="/">
            <AuthModal onClose={() => setAuthModalOpen(false)} isOpen={authModalOpen} />

            </Route>
            
          </Grid>
        </Switch>
        </>))}
        <Header/>
      {user !== undefined && user !== null && (
        <Switch>
          <Grid
            style={{
              display: 'flex',
              flexGrow: 1,
              width: '100%',
              height: '100%',
            }}
            xs={12}
          >
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route exact path="/stores">
              <Store />
            </Route>
            <Route path="/orders">
              <Orders />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
          </Grid>
        </Switch>
      )}
    </Elements>
  )
}

export default App
