import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/styles';
import {useSelector, useDispatch} from "react-redux";

export default function BasicTable() {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [selected, setSelected] = React.useState([])
  const stores = useSelector((state) => state.main.stores)
  const orders = useSelector((state) => state.main.orders)

  const handleOrderRowClick = () => {
    console.log("Clicked");
  }
  const isSelected = (name) => selected.indexOf(name) !== -1

  const theme = useTheme();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: theme.palette.secondary.main, color:'white' }}>
          <TableRow style={{color:'white'}}>
            <TableCell style={{color:'white'}} align="left" >Order ID</TableCell>
            <TableCell style={{color:'white'}} align="left" >Store ID</TableCell>
            <TableCell style={{color:'white'}} align="left">Trip ID</TableCell>
            <TableCell style={{color:'white'}}align="left" >Member Name </TableCell>
            <TableCell style={{color:'white'}} align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[1, 2, 3, 'Object.keys(orders)'].map((key, index) => {
            const row = {
              storeID: 1,
              tripID: 5,
              items: [
                {
                  member: 'membername'
                }
              ]
            }
            const isItemSelected = isSelected(key)
            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <TableRow
                hover
                onClick={() => handleOrderRowClick(orders[key])}
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={key}
                selected={isItemSelected}
              >
                <TableCell component="th" id={labelId} scope="row">
                  {key}
                </TableCell>
                <TableCell align="left">'stores[row.storeID].name'</TableCell>
                <TableCell align="left">{row.tripID}</TableCell>
                <TableCell align="left">{row.items[0].member}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" disableElevation color="redorange" style={{textTransform: 'none'}} sx={{width:'75%'}} size="small">
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
