import React, {useState, useRef, useEffect} from "react";
import {
  Button,
  TextField,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonBase,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Card,
  Box
} from "@mui/material/";
import {makeStyles, useTheme} from "@mui/styles";
import AddIcon from "@material-ui/icons/Add";
import Image from "material-ui-image";
import { copyBrandImage, createProduct, setProductUrls, uploadProductImage } from "../redux/interactions";
import { pushProduct } from '../redux/productsSlice'

import {useSelector, useDispatch} from "react-redux";

import {
  doc,
  updateDoc,
  arrayUnion,
  addDoc,
  collection,
  setDoc
} from "firebase/firestore";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage";

import {db, storage} from "../firebase";

import Categories from "../components/product/Categories";
import StoreAvailability from "./StoreAvailability";

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      boxShadow: '10px 5px 5px #1f2c33'
    }
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15
      },
      "& $imageMarked": {
        opacity: 0
      },
      "& $imageTitle": {
        border: "4px solid currentColor"
      }
    }
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
}));

export default function ProductCreationModal({addProduct, productID}) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState("");
  const [storeIDs, setStoreIDs] = React.useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleChangeCategory = event => {
    setCategory(event.target.value);
  };
  const uid = useSelector(state => state.auth.user);
  const stores = useSelector(state => state.main.stores);
  // Create a storage reference from our storage service
  const brandRef = ref(storage, uid);

  const productImageRefs = [
  //  ref(brandRef, `products/${productID}/1.jpg`),
  //  ref(brandRef, `products/${productID}/2.jpg`),
  //  ref(brandRef, `products/${productID}/3.jpg`),
  //  ref(brandRef, `products/${productID}/4.jpg`)
    0,
    1,
    2,
    3
  ];

  const [productDownloadURLs, setProductDownloadURLs] = useState([
    null,
    null,
    null,
    null
  ]);

  /*
  useEffect(() => {
    // Update the document title using the browser API
    console.log("USE EFFECT CALLED");
    const temp = [];
    productImageRefs.forEach((reference, i) => {
      getDownloadURL(reference)
        .then(url => {
          temp[i] = url;
        })
        .catch(error => {
          // Handle any errors
          temp[i] = null;
        });
    });
    setProductDownloadURLs(temp);
  }, [uid]);
   */



    const getNewURLs = (url, index) => {
      console.log("getNewURLs CALLED: ", url, index);
      const temp = [...productDownloadURLs];
      temp[index] = url;
      setProductDownloadURLs(temp);
    };

    const inputFiles = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [filters, setFilters] = React.useState([]);

    const onFileChange = (event, index) => {
      const imageurl = URL.createObjectURL(event.target.files[0])
      //console.log("getNewURLs CALLED: ", url, index);
      const temp = [...productDownloadURLs];
      temp[index] = imageurl;
      setProductDownloadURLs(temp);
      uploadProductImage(event.target.files[0], `${uid}/temp/${index}.png`)
      // Upload To Firebase Storage
      /*
      uploadBytes(productImageRefs[index], event.target.files[0]).then(
        snapshot => {
          getDownloadURL(productImageRefs[index])
            .then(url => {
              getNewURLs(url, index);
            })
            .catch(error => {
              console.log("ERROR GETTING DOWNLOAD LINK: ", error);
              // Handle any errors
            });
        }
      );
       */

    };

    const handleAddProduct = async () => {
      
      console.log("Filters: ", filters);
      const createProdRes = await createProduct({
        brand_id: uid,
        name,
        description,
        filters,
        category,
      }
      )
      console.log("createProdRes: ", createProdRes);
      const prodId = createProdRes.id
      // createProdRes has the id to be used when updating the images and urls
      // TODO: Copy S3 Temp Folder to the urls for this item then update product
      try {
        const copyRes1 = await copyBrandImage(uid, prodId, 0);
        const copyRes2 = await copyBrandImage(uid, prodId, 1);
        const copyRes3 = await copyBrandImage(uid, prodId, 2);
        const copyRes4 = await copyBrandImage(uid, prodId, 3);
        console.log("copyRes1:", copyRes1);
        console.log("copyRes2:", copyRes2);
        console.log("copyRes3:", copyRes3);
        console.log("copyRes4:", copyRes4);
      } catch (error) {
        console.log("There was an error copying images: ", error);
      }
      
      const productImageUrls = [
        `https://tripwardrobe-storage192857-staging.s3.amazonaws.com/public/productimages/${uid}/${prodId}/0.png`,
        `https://tripwardrobe-storage192857-staging.s3.amazonaws.com/public/productimages/${uid}/${prodId}/1.png`,
        `https://tripwardrobe-storage192857-staging.s3.amazonaws.com/public/productimages/${uid}/${prodId}/2.png`,
        `https://tripwardrobe-storage192857-staging.s3.amazonaws.com/public/productimages/${uid}/${prodId}/3.png`,
      ]
      try {
        const urlUpdateRes = await setProductUrls(prodId, productImageUrls);
        console.log("urlUpdateRes: ", urlUpdateRes);
        dispatch(pushProduct({product: urlUpdateRes}));
        
      } catch (error) {
        console.log("There was an error updating image urls: ", error);
      }
      
      

      // editProduct({ name, description, inventory, filters, category, productDownloadURLs })
      // Add Product ID To Each Store ID Document Along With Array Union For Category
      /*
      storeIDs.forEach(id => {
        const storeRef = doc(db, "stores", id);
        updateDoc(storeRef, {
          productIDs: arrayUnion(productID),
          categories: arrayUnion(category)
        });
      });
       */

      handleClose();
    };

    const addStoreID = storeID => {
      setStoreIDs([...storeIDs, storeID]);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const [name, setName] = React.useState("");
    const handleNameChange = e => {
      setName(e.target.value);
    };

    const [description, setDescription] = React.useState("");
    const handleDescriptionChange = e => {
      setDescription(e.target.value);
    };

    const [inventory, setInventory] = React.useState("");
    const handleInventoryChange = e => {
      setInventory(e.target.value);
    };
    const [hovered, setHovered] = React.useState(false);

  return (
    <div>
      <Card className={classes.root} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <Box style={{backgroundColor: hovered ? 'lightGrey' : 'grey', height: 200}}>
          <Grid container style={{justifyContent:'center', height:'100%', alignItems:'center'}}>
            <Button variant="contained" color="redorange" onClick={handleClickOpen}>
              <AddIcon /> Add A Product
            </Button>
          </Grid>
        </Box>
        <Box style={{ height: 50}}>
          <Grid container onClick={handleClickOpen} style={{justifyContent:'center', height:'100%', alignItems:'center'}}>
            <Typography> New Product</Typography>
          </Grid>
        </Box>
      </Card>


      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Product Creation</DialogTitle>
        <DialogContent>
          <DialogContentText>Product Images</DialogContentText>
          <Grid
            container
            style={{padding: 10, marginTop: 10, justifyContent: "space-around"}}
          >
            {productImageRefs.map((imageRef, index) => (
              <Grid item style={{padding: 10, marginBottom: 15}} xs={5}>
                <input
                  style={{display: "none"}}
                  ref={inputFiles[index]}
                  type="file"
                  onChange={e => onFileChange(e, index)}
                />
                <ButtonBase
                  focusRipple
                  onClick={() => inputFiles[index].current.click()}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                  style={{
                    width: "100%"
                  }}
                >
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${productDownloadURLs[index]})`
                    }}
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      Image {index + 1}
                      <span className={classes.imageMarked} />
                    </Typography>
                  </span>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>

          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            onChange={handleChangeCategory}
          >
            <MenuItem value="Family">Family</MenuItem>
            <MenuItem value="Together">Together</MenuItem>
            <MenuItem value="Personal">Personal</MenuItem>
            <MenuItem value="Lifestyle">Lifestyle</MenuItem>
            <MenuItem value="Trending">Trending</MenuItem>
            <MenuItem value="Designer">Designer</MenuItem>
          </Select>

          <DialogContentText style={{paddingTop: 15}}>
            Product Filters
          </DialogContentText>
          <Categories setFilters={setFilters} />

          <TextField
            onChange={handleNameChange}
            margin="dense"
            id="name"
            label="Product Name"
            fullWidth
          />
          <TextField
            onChange={handleDescriptionChange}
            margin="dense"
            id="name"
            label="Product Description"
            fullWidth
          />
          {/*
          <TextField
            onChange={handleInventoryChange}
            margin="dense"
            id="name"
            label="Product Inventory"
            fullWidth
          />
          <DialogContentText style={{paddingTop: 15}}>
            Select Stores With Product Availability
          </DialogContentText>
          <StoreAvailability addStoreID={addStoreID} stores={stores} />
          */}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddProduct} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
