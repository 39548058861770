import React, {useState, useRef, useEffect} from "react";
import {
  Grid,
  Typography,
  Button,
  Fab,
  TextField,
  Input,
  InputAdornment,
  IconButton,
  Chip,
  Card
} from "@mui/material/";
import Image from "material-ui-image";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import StoresTable from "../components/home/StoresTable";
import StoreCreationModal from "../modals/StoreCreationModal";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {doc, addDoc, collection, setDoc, updateDoc} from "firebase/firestore";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage";
import {db, storage} from "../firebase";
import uploadLogo from "../UploadLogo.png";
import BrandCard from "../components/home/BrandCard";


function Home() {
  // let history = useHistory()
  const uid = useSelector(state => state.auth.user);
  const stores = useSelector(state => state.main.stores);
  const brandData = useSelector(state => state.main.brandData);
  // CREATE REF FOR BRAND DATABASE DOCUMENT
  let brandDBRef;
  let brandRef;
  let mainBrandImageRef;
  if (uid) {
    // Create a storage reference from our storage service
    brandDBRef = doc(db, "brands", uid);
    brandRef = ref(storage, uid);
    mainBrandImageRef = ref(brandRef, "logo.jpg");
  }
  const inputFile = useRef(null);
  const [brandName, setBrandName] = useState(brandData.name);
  const [hovered, setHovered] = useState(false);
  console.log("BRAND DATA: ", brandData);
  const [brandDescription, setBrandDescription] = useState(
    brandData.description
  );
  const [currentDownloadURL, setCurrentDownloadURL] = useState(null);
  const [editingName, setEditingName] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);

  const updateBrandDoc = async obj => {
    console.log("UPDATE BRAND DOC WITH OBJ: ", obj);
    await updateDoc(brandDBRef, obj);
  };

  useEffect(() => {
    // Update the document title using the browser API
    console.log("USE EFECT HIT");
    if (mainBrandImageRef !== undefined) {
      getDownloadURL(mainBrandImageRef)
        .then(url => {
          console.log("DONLOAD URL: ", url);
          setCurrentDownloadURL(url);
        })
        .catch(error => {
          console.log("ERROR GETTING DOWNLOAD LINK: ", error);
          // Handle any errors
        });
    }
  }, [uid]);

  useEffect(() => {
    console.log("BRAND DATA CHANGED");
    // Update the document title using the browser API
    setBrandDescription(brandData.description)
    setBrandName(brandData.name)
  }, [brandData]);

  // On file select (from the pop up)
  const onFileChange = event => {
    uploadBytes(mainBrandImageRef, event.target.files[0]).then(snapshot => {
      setCurrentDownloadURL(null);
      getDownloadURL(mainBrandImageRef)
        .then(url => {
          setCurrentDownloadURL(url);
          updateBrandDoc({logoURL: url});
        })
        .catch(error => {
          console.log("ERROR GETTING DOWNLOAD LINK: ", error);
          // Handle any errors
        });
    });
  };

  const addStore = async obj => {
    const storeDoc = await addDoc(collection(db, "stores"), obj);
  };

  return (
    <Grid container justify="center" style={{width: "100%", height: "100%", justifyContent:'center'}} sx={{p:2}}>
      <BrandCard/>
    </Grid>
  );
}

export default Home;
