import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import { signIn, signOut, signUp, listen } from '../redux/interactions'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../redux/authSlice'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios'

import { Auth } from 'aws-amplify';


export default function SubscribeModal({ onClose }) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [authState, setAuthState] = useState(1)
    const [authError, setAuthError] = useState('')
    const [agreed, setAgreed] = useState(false)
    const [selectedPrice, setSelectedPrice] = useState(0)


    const handleCheckout = async () => {
        const url = await axios({
            method: 'post',
            url: 'https://6wgozgasrjmampo5tqku6jxcfa0snfhk.lambda-url.us-east-1.on.aws/',
            data: {
                lineItems: [{ price: 'price_1LcN2mDwHVW4N2Br6R2GWpfG', quantity: 1 }],
                lastName: "Sbp"
            }
        });
        const checkoutSessionId = url.data.replace("https://checkout.stripe.com/c/pay/", "")
        console.log("checkout id: ", checkoutSessionId);

        window.open(url.data, "_blank")
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        onClose()
    }

    return (
        <>
        <Button onClick={handleClickOpen}>
            Choose Plan
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{ borderRadius: 0 }} PaperProps={{ style: { borderRadius: 0 } }}>
            <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, mb: 2 }} style={{ color: 'white' }}>
                Choose Your Billing Plan
            </DialogTitle>


            <DialogContent>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Plan</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Limited"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="li" control={<Radio />} label="Limited License Plan for $199.00/annually." />
                        <FormControlLabel value="exl" control={<Radio />} label="Exclusive License Plan for $399.00/annually." />
                        <FormControlLabel value="fs" control={<Radio />} label="Full-Systems License Plan for $799.00/annually." />
                        <FormControlLabel value="fi" control={<Radio />} label="Full-Integration License Plan for $1599/annually." />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCheckout}>
                    Checkout →
                </Button>
            </DialogActions>
        </Dialog>
        </>
        
    )
}
