import React from 'react'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Image from 'material-ui-image'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentProduct } from '../../redux/mainSlice'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    minHeight: 150,
    maxHeight: 200,
  },
})

export default function Product({ product }) {
  let history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  console.log('PRODUCT COMPONENT: ', product)
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => {history.push('/product'); dispatch(setCurrentProduct(product))}}>
        <Image src="http://loremflickr.com/100/100" />
        <CardContent>
          <Typography gutterBottom variant="h6" component="p">
            {product.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
