/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $description: String!
    $logo_key: String!
    $name: String!
  ) {
    createBrand(description: $description, logo_key: $logo_key, name: $name) {
      description
      logo_key
      name
      id
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand($description: String!, $id: ID!, $title: String!) {
    updateBrand(description: $description, id: $id, title: $title) {
      description
      logo_key
      name
      id
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $name: String!
    $address: String!
    $delivery_range: Float!
    $hours: String!
    $rep_name: String!
    $rep_email: String!
    $rep_phone: String!
  ) {
    createStore(
      name: $name
      address: $address
      delivery_range: $delivery_range
      hours: $hours
      rep_name: $rep_name
      rep_email: $rep_email
      rep_phone: $rep_phone
    ) {
      id
      name
      address
      delivery_range
      hours
      rep_name
      rep_email
      rep_phone
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $brand_id: ID!
    $name: String!
    $description: String!
    $filters: [String]
    $category: String!
    $urls: [String]
  ) {
    createProduct(
      brand_id: $brand_id
      name: $name
      description: $description
      filters: $filters
      category: $category
      urls: $urls
    ) {
      brand_id
      id
      name
      description
      filters
      category
      urls
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $customer_id: String
    $check_in: String
    $check_out: String
    $location: String
    $status: String
    $name: String
    $checkout_session_id: String
    $product_ids: [String]
    $brand_ids: [String]
  ) {
    createOrder(
      customer_id: $customer_id
      check_in: $check_in
      check_out: $check_out
      location: $location
      status: $status
      name: $name
      checkout_session_id: $checkout_session_id
      product_ids: $product_ids
      brand_ids: $brand_ids
    ) {
      id
      customer_id
      check_in
      check_out
      location
      status
      name
      checkout_session_id
      product_ids
      brand_ids
    }
  }
`;
export const updateProductUrls = /* GraphQL */ `
  mutation UpdateProductUrls($id: ID!, $urls: [String]) {
    updateProductUrls(id: $id, urls: $urls) {
      brand_id
      id
      name
      description
      filters
      category
      urls
    }
  }
`;
