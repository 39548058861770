/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrdersForBrand = /* GraphQL */ `
  query GetOrdersForBrand {
    getOrdersForBrand {
      id
      customer_id
      check_in
      check_out
      location
      status
      name
      checkout_session_id
      product_ids
      brand_ids
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand {
    getBrand {
      description
      logo_key
      name
      id
    }
  }
`;
export const getStoresForBrand = /* GraphQL */ `
  query GetStoresForBrand {
    getStoresForBrand {
      id
      name
      address
      delivery_range
      hours
      rep_name
      rep_email
      rep_phone
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID) {
    getStore(id: $id) {
      id
      name
      address
      delivery_range
      hours
      rep_name
      rep_email
      rep_phone
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID) {
    getProduct(id: $id) {
      brand_id
      id
      name
      description
      filters
      category
      urls
    }
  }
`;
export const getProducts = /* GraphQL */ `
  query GetProducts {
    getProducts {
      brand_id
      id
      name
      description
      filters
      category
      urls
    }
  }
`;
export const getProductsForBrand = /* GraphQL */ `
  query GetProductsForBrand {
    getProductsForBrand {
      brand_id
      id
      name
      description
      filters
      category
      urls
    }
  }
`;
